<nz-layout  >
  <nz-content>
    <div class="main-content">
      <div class="reset-container" >
        <a (click)="resetScore()" [style.color]="'white'">Reset</a>
      </div>
      <div class="top-score">
        <div class="player-text-holder">
          You
        </div>
        {{ yourScore }}
      </div>
      <div class="top-buttons-holder">
        <a class="minus-holder" (click)="decreaseYourScore()">
          -
        </a>
        <a class="plus-holder" (click)="increaseYourScore()">
          +
        </a>
      </div>
      <div class="bottom-score" >
        <div class="player-text-holder">
          Them
        </div>
        {{ theirScore }}
      </div>
      <div class="bottom-buttons-holder">
        <a class="minus-holder" (click)="decreaseTheirScore()">
          -
        </a>
        <a class="plus-holder" (click)="increaseTheirScore()">
          +
        </a>
      </div>
    </div>
  </nz-content>
</nz-layout>
